<template>
  <div>
    <a-card :tab-list="tabList" :active-tab-key="currentTab" @tabChange="tabChange">
      <div v-if="currentTab === 'RepairTaskPanel'">
        <RepairTaskPanel />
      </div>
      <div v-if="currentTab === 'RepairRecordPanel'">
        <RepairRecordPanel />
      </div>
    </a-card>
  </div>
</template>

<script>
export default {
  components: {
    RepairTaskPanel: () => import("./RepairTaskPanel"),
    RepairRecordPanel: () => import("./RepairRecordPanel"),
  },
  data() {
    return {
      tabList: [
        {
          key: "RepairTaskPanel",
          tab: "维修任务",
        },
        {
          key: "RepairRecordPanel",
          tab: "维修记录",
        },
      ],
      currentTab: undefined,
    };
  },
  methods: {
    initData() {
      let currentTab = this.$route.query.currentTab;
      if (currentTab) {
        this.currentTab = currentTab;
      } else {
        this.currentTab = this.tabList[0].key;
        this.$router.push({ query: { currentTab: this.currentTab } });
      }
    },
    tabChange(key) {
      this.currentTab = key;
      this.$router.push({ query: { currentTab: this.currentTab } });
    },
  },
  mounted() {
    this.initData();
  },
};
</script>
